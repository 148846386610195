<template>
  <div class="flow-info">
    <p class="firstLetterUppercase">{{ h.date.date|formatDateDistance }} em {{ h.date.date|formatDate }} <span class="withSeparator">{{h.person}}</span> </p>
  </div>
</template>

<script>
export default {
  name: "FlowInfo",
  props: ['h']
}
</script>
