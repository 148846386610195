<template>
  <li class="with-box note">
    <i class="box-icon material-icons">description</i>
    <div class="with-box-content ">
      <flow-info :h="h"/>
      <div v-html="h.newObject.note"></div>
      <div v-if="h.newObject.pessoa && h.newObject.processo" class="m-t font-thin font-12">Contato realizado com <a @click="gotoPerson(h.newObject.pessoa)">{{ h.newObject.pessoa.name }}</a></div>
      <div v-if="h.newObject.processo && !h.newObject.pessoa" class="m-t font-thin font-12">Contato realizado sobre o processo <a @click="gotoProcesso(h.newObject.processo)">{{ h.newObject.processo.numero|formataNumeroProcesso }}</a></div>
    </div>
    <div class="note-comments">
      <u-btn
          @click="showCommentsTabFcn"
          class="m-l m-t-xs btn-comment simple-btn"
          no-caps flat dense>
        {{ commentsTabBtnLabel }} <i v-if="hasComments && !showCommentsTab" class="material-icons">expand_more</i> <i
          v-if="hasComments && showCommentsTab" class="material-icons">expand_less</i>
      </u-btn>
      <div v-if="showCommentsTab && (!hasComments || forceShowEditor)" class="comment-main">
        <div class="comment-person-photo cont-avatar">
          <img v-if="$uloc.auth.session.user.photo" :src="this.$uloc.auth.session.user.photo">
          <div v-else class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>
        </div>
        <div class="comment-container pessoa-app-observacao">
          <s-l-editor
              ref="editor"
              v-model="comment"
              placeholder="Faça uma anotação, @nome..."
              :show-toolbar-on-focus="true"
              :close-click-outside="false"
              autofocus
              @focus="() => { isCommenting = true }"
          />
          <div class="editor-actions bodyBox">
            <div class="editor-form-btns infoCont">
              <u-btn @click="cancel" :disable="loading" label="Cancelar" no-caps
                     class="btnContato btnForm btnWhite m-r-xs m-l-none"/>
              <u-btn @click="salvar" :loading="loading" type="submit" label="Salvar configurações" no-caps
                     class="btnContato btnForm no-border"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showCommentsTab && hasComments && !forceShowEditor">
        <div class="comment-main fake">
          <div class="comment-person-photo cont-avatar">
            <img v-if="$uloc.auth.session.user.photo" :src="this.$uloc.auth.session.user.photo">
            <div v-else class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>
          </div>
          <div @click="() => { forceShowEditor = true }" class="comment-container pessoa-app-observacao fake-input">
            Escreva um comentário, @nome...
          </div>
        </div>
      </div>
      <div v-if="showCommentsTab && (comments && comments.length)">
        <div v-for="noteComment in comments" :key="'comment-' + noteComment.id" class="comment-main fake">
          <div class="comment-person-photo cont-avatar">
            <img v-if="noteComment.photo" :src="noteComment.photo">
            <div v-else class="img-fake">{{ firstLettersName(noteComment.createdByName) }}</div>
          </div>
          <div class="comment-data">
            <div class="with-box-content note no-bg">
              <div class="flow-info">
                <p class="firstLetterUppercase">{{ noteComment.createdAt.date|formatDateDistance }} em
                  {{ noteComment.createdAt.date|formatDate }} <span
                      class="withSeparator">{{ noteComment.createdByName }}</span></p>
              </div>
              <div v-html="noteComment.comment"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="commentsLoading">
        <div class="comment-main fake">
          <div class="comment-person-photo cont-avatar">
            <div class="img-fake no-bg">
              <u-fake-loader height="33px" style="height: 100%"/>
            </div>
          </div>
          <div class="comment-data">
            <u-fake-loader width="20%"/>
            <u-fake-loader width="80%"/>
          </div>
        </div>
      </div>
      <button style="background-color: transparent; top: 10px" class="box-menu">
        <i class="fal fa-ellipsis-h"></i>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <menu-options-item @click="excluir(h)" label="Excluir" close/> <!-- @TODO: Permissao & Log -->
            </ul>
          </div>
        </u-popover>
      </button>
    </div>
  </li>
</template>

<script>
import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"
import FlowInfo from "@/components/pessoa/components/include/historico/FlowInfo"
import SLEditor from "@/components/layout/components/Editor"
import {criarNota, deleteNota, showNota} from "@/domain/pessoa/services"
import {UFakeLoader, UPopover} from "uloc-vue"
import MenuOptionsItem from "@/components/layout/context-menu/context-window-options-item"

export default {
  name: "FlowNote",
  components: {SLEditor, FlowInfo, UFakeLoader, MenuOptionsItem, UPopover},
  mixins: [flowMixin],
  data() {
    return {
      showCommentsTab: false,
      isCommenting: false,
      loading: false,
      comment: '',
      forceShowEditor: false,
      comments: null,
      commentsLoading: false,
      forceCommentsCount: 0
    }
  },
  computed: {
    hasComments() {
      let n = Number(this.h.newObject.comments)
      return n > 0 || this.forceCommentsCount > 0
    },
    commentsTabBtnLabel() {
      let label = 'Adicionar comentário'
      if ((this.h && this.h.newObject.comments) || this.forceCommentsCount) {
        let n = this.forceCommentsCount || Number(this.h.newObject.comments)
        label = n + ' comentário' + (n > 1 ? 's' : '')
      }
      return label
    }
  },
  methods: {
    cancel() {
      this.$refs.editor.forceBlur()
      this.isCommenting = false
      this.showCommentsTab = false
      this.comment = ''
    },
    salvar() {
      console.log('Save...')
      this.loading = true
      const contents = this.$refs.editor.htmlContent()
      const marks = []
      const marksNodes = this.$refs.editor.contentNode().querySelectorAll('[data-person-mark-id]')
      if (marksNodes) {
        for (let i = 0; i < marksNodes.length; i++) {
          marks.push(marksNodes[i].dataset.personMarkId)
        }
      }
      const data = {
        contents: contents,
        marks: marks,
        parent: this.h.newObject.id
      }
      let method = (data) => criarNota(this.appContainer.pessoa, data)
      if (this.appContainer.intercept) {
        method = this.appContainer.intercept.criarNota
      }

      method(data).then(response => {
        // @TODO: <URGENTE> Emitir evento para carregar no histórico se estiver renderizado em tela
        this.cancel()
        this.loading = false
        this.$uloc.notify({
          color: 'black',
          message: `Nota criada.`,
          position: 'bottom-left'
        })
        let n = Number(this.h.newObject.comments || 0)
        n = n + 1
        this.h.newObject.comments = n
        this.forceCommentsCount = n
        console.log('NEW', n)
        this.showCommentsTab = true
        this.forceShowEditor = false
        this.isCommenting = false
        this.comment = ''
        this.$refs.editor.forceBlur()
        this.$forceUpdate()
        this.loadComments()
      })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    },
    showCommentsTabFcn() {
      this.showCommentsTab = !this.showCommentsTab
      this.forceShowEditor = false
      this.comments = null
      if (this.hasComments && this.showCommentsTab) {
        this.loadComments()
      }
    },
    loadComments() {
      this.commentsLoading = true
      let method = (data) => showNota(this.appContainer.pessoa, data)
      if (this.appContainer.intercept) {
        method = this.appContainer.intercept.showNota
      }

      method(this.h.newObject.id).then(({data}) => {
        this.commentsLoading = false
        this.comments = data.comments
      })
          .catch(error => {
            this.commentsLoading = false
            this.alertApiError(error)
          })
    },
    excluir(h) {
      this.$uloc.dialog({
        title: 'Remover nota',
        message: `Tem certeza que deseja excluir esta nota? O sistema irá registrar o log de exclusão.`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não'
      })
          .then(() => {
            let method = (data) => deleteNota(this.appContainer.pessoa, data)
            if (this.appContainer.intercept) {
              method = this.appContainer.intercept.deleteNota
            }
            method(h.referId).then(({data}) => {
              this.$emit('remove', h)
            })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    gotoPerson (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    },
    gotoProcesso (p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'processo', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
