<template>
  <li class="no-icon flow-info">
    <h4 v-if="action === 'delete'"><span class="title">{{translate(subject)}}: <del>{{ translate(subject, obj.old) }}</del> </span></h4>
    <h4 v-else-if="action === 'create'"><span class="title">{{translate(subject)}}: {{ translate(subject, obj.new) }}</span></h4>
    <h4 v-else-if="action === 'change'"><span class="title">{{translate(subject)}}:</span> <span class="font-thin" style="text-decoration: line-through">{{translate(subject, obj.old)}}</span> <span><i class="material-icons" style="font-size: 12px">arrow_forward</i> {{translate(subject, obj.new)}}</span></h4>
    <h4 v-else-if="action === 'follow'">
      <span class="title">Seguidores:</span> <span class="text-positive">+1</span> <span class="capitalize">{{obj.name}}</span>
    </h4>
    <h4 v-else-if="action === 'unfollow'">
      <span class="title">Seguidores:</span> <span class="text-negative">-1</span> <span class="capitalize" style="text-decoration: line-through">{{obj.name}}</span>
    </h4>
    <p class="firstLetterUppercase">{{ h.date.date|formatDateDistance }} em {{ h.date.date|formatDate }}
      <span class="withSeparator">{{h.person || h.user}} <!--(Aplicativo web)--></span></p>
  </li>
</template>

<script>
import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"
import i18n from "@/domain/pessoa/helpers/i18n"

export default {
  name: "FlowModify",
  mixins: [flowMixin],
  methods: {
    translate (name, val = null) {
      if (val === true) {
        return 'Sim'
      }
      if (val === false) {
        return 'Não'
      }
      let n = String(name).toLowerCase()
      if (typeof i18n[n] !== 'undefined') {
        if (val !== null) {
          return Array.isArray(i18n[n]) ? i18n[n][1](val) : val
        }
        return Array.isArray(i18n[n]) ? i18n[n][0] : i18n[n]
      }
      return val || name
    }
  }
}
</script>
