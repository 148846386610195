<template>
  <pessoa-aba class="pessoa-app-observacao">
    <div>
    <sl-editor
        ref="editor"
        v-model="html"
        :placeholder="placeholder"
        :show-toolbar-on-focus="true"
        :close-click-outside="false"
        @focus="() => { editing = true }"
    />
    <div v-if="editing" class="editor-actions bodyBox">
      <div class="editor-form-btns infoCont">
        <u-btn @click="cancel" :disable="loading" label="Cancelar" no-caps
               class="btnContato btnForm btnWhite m-r-xs m-l-none"/>
        <u-btn @click="salvar" :loading="loading" type="submit" label="Salvar configurações" no-caps
               class="btnContato btnForm no-border"/>
      </div>
    </div>
<!--          <br><br>
          <div>{{html}}</div>-->
    </div>
    <div class="editor-no-pointer" v-if="editing && parseProps && parseProps.contatos && parseProps.contatos.length">
      <div class="wrapper m-t-n full-width">
        <label class="m-b-xs block">Contato feito com</label>
        <div style="min-width: 40%; max-width: 50%">
          <select class="erp-input" v-model="copyToPerson" id="contact_person" >
            <option v-for="(pessoa, i) in parseProps.contatos" :value="pessoa"
                    :key="'contact-person-' + i">{{pessoa.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </pessoa-aba>
</template>

<script>
/**
 * @TODO: <RAPHAEL>: Guardar rascunho da nota no storage do browser.
 */
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import SlEditor from "@/components/layout/components/Editor"
import AppMixin from './appMixin'
import {criarNota} from "@/domain/pessoa/services"
import HistoryState from "@/domain/pessoa/helpers/HistoryState"

export default {
  name: "Observacoes",
  mixins: [AppMixin],
  props: {
    type: null
  },
  data() {
    return {
      html: '',
      copyToPerson: null,
      editing: false,
      loading: false,
      criarNota: criarNota
    }
  },
  components: {
    PessoaAba,
    SlEditor
  },
  mounted() {
    if (this.appMethods) {
      if (this.appMethods.criarNota) {
        this.criarNota = this.appMethods.criarNota
      }
    }
  },
  watch: {
    editing () {
      if (this.parseProps && this.parseProps.contatos && this.parseProps.contatos.length) {
        if (this.parseProps.contatos.length === 1) {
          this.copyToPerson = this.parseProps.contatos[0]
        } else {
          this.copyToPerson = this.parseProps.contatos.find(c => c.default)
        }
      }
    }
  },
  computed: {
    placeholder () {
      if (this.type && this.type === 1) {
        return 'Descreva sobre como foi o contato, em detalhes. Marque pessoas com @nome...'
      }
      return 'Faça uma anotação, @nome...'
    }
  },
  methods: {
    cancel() {
      this.$refs.editor.forceBlur()
      this.editing = false
      this.html = ''
    },
    salvar() {
      console.log('Save...')
      this.loading = true
      const contents = this.$refs.editor.htmlContent()
      console.log(contents, this.html)
      const marks = []
      const marksNodes = this.$refs.editor.contentNode().querySelectorAll('[data-person-mark-id]')
      if (marksNodes) {
        for (let i = 0; i < marksNodes.length; i++) {
          marks.push(marksNodes[i].dataset.personMarkId)
        }
      }
      const data = {
        type: this.type,
        contents: contents,
        marks: marks
      }
      if (this.copyToPerson) {
        data.copyToPerson = this.copyToPerson.id || this.copyToPerson
      }
      this.criarNota(this.parseProps.pessoa, data)
          .then(response => {
            HistoryState.listen && HistoryState.listen(response.data.id)
            this.cancel()
            this.loading = false
            this.$uloc.notify({
              color: 'black',
              message: `Nota criada.`,
              position: 'bottom-left'
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>

<style lang="stylus">
.pessoa-app-observacao {
  .sl-editor {
    .sl-editor-content {
      min-height 60px
    }
  }

  .editor-actions {
    border none !important;
    padding 0 !important
    text-align right
    position relative

    .editor-form-btns {
      position absolute
      top: -40px
      right 0
    }
  }
}
</style>
