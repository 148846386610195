import {format, parseISO} from 'date-fns'
export default {
    name: 'Nome',
    type: 'Tipo',
    document: 'Documento',
    nationality: 'Nacionalidade',
    birthdate: ['Nascimento', (date) => {
        return format(parseISO(date.date || date), 'dd/MM/yyyy')
    }],
    gender: ['Gênero', (v) => {
        if (Number(v) === 1) {
            return 'Masculino'
        }
        return 'Feminino'
    }],
}