<template>
<pessoa-aba>
  <div class="pessoa-arquivo-area cursor-pointer dropzone-area flex flex-center justify-between "
       @click="dropClickEvent"
       :class="{'dropzoneEnter': dropzone, 'minHeight': !isUploading || !files || !files.length}"
       @dragenter.prevent.stop="dropzone = true"
       @dragover.prevent.stop="dropzone = true"
       @drop.prevent.stop="inputListener"
       @dragleave.prevent.stop="dropzone = false">
    <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
           v-show="false">
    <div v-if="!isUploading || !files || !files.length" class="legend text-center col-grow" style="margin-top: -20px">Arraste arquivos para cá ou <a>selecione no seu computador</a></div>
    <div class="col-grow w-full wrapper" style="margin-top: -20px" v-else>
      <div class="uploader-container">
        <ul>
          <li v-for="(file, i) in files" :key="i">
            <div class="filename">{{file.file.name}}</div>
            <div class="filesize">
              <u-progress v-if="file.copying" :percentage="file.progress" :animate="true"
                          :color="file.progress < 85 ? 'grey' : 'positive'" height="10px" />
              <span v-else-if="file.fail"><a @click.prevent.stop="upload(file)" style="text-transform: none; color: red">Falha. Tentar novamente</a></span>
              <span v-else>{{filesize(file.file.size)}}</span>
            </div>
            <div class="options"><u-icon @click.native.prevent.stop="removeFile(file)" name="delete" /></div>
          </li>
        </ul>
      </div>
      <div class="flex justify-between m-t uploader-options">
        <div>
          <a @click.stop.prevent="$refs.input.click()" class="pos-rlt" style="z-index: 3"><u-icon name="attach_file" /> Anexar arquivos</a>
        </div>
        <div class="text-right"><a @click.stop.prevent="reset">Fechar</a></div>
      </div>
    </div>
  </div>
</pessoa-aba>
</template>

<script>
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import {fileToBase64} from "@/utils/fileToBase64"
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import {UProgress} from "uloc-vue"
import {humanStorageSize} from "uloc-vue/src/utils/format"
import {uploadArquivoPessoa} from "@/domain/pessoa/services"

export default {
  name: "PessoaArquivos",
  mixins: [AppMixin],
  components: {PessoaAba, UProgress},
  data () {
    return {
      dropzone: false,
      files: [],
      fileTypes: '*',
      isUploading: false,
      uploadService: uploadArquivoPessoa
    }
  },
  computed: {
    existsFilesToUpload () {
      return this.files.some(file => !file.done)
    }
  },
  mounted() {
    if (this.appMethods) {
      if (this.appMethods.uploadArquivo) {
        this.uploadService = this.appMethods.uploadArquivo
      }
    }
  },
  methods: {
    dropClickEvent (e) {
      !this.files.length && this.$refs.input.click()
    },
    inputListener (e) {
      console.log(e)
      this.dropzone = false
      // let files = []

      let bindFile = (file) => {
        if (!file.progress) {
          return {
            file: file,
            filename: file.name,
            done: false,
            copying: false,
            progress: 0,
            fail: false,
            success: false,
            tipo: this.tipoDefault,
            nome: null,
            permissao: 0
          }
        }
        return file
      }

      // For drag via dataTransfer
      if (e.dataTransfer && e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === 'file') {
            this.files.push(bindFile(e.dataTransfer.items[i].getAsFile()))
          }
        }
      } else if (e.dataTransfer && e.dataTransfer.files) {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.files.push(bindFile(e.dataTransfer.files))
        }
      }

      // For input via target.files
      if (e.target.files && e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.files.push(bindFile(e.target.files[i]))
        }
      }

      this.isUploading = true
      console.log('Adicionando arquivos: ', this.files)
      this.iniciaEnvios()
    },
    removeFile (file) {
      // @TODO: <RAPHAEL> Se já foi feito upload (verificar file.id), chamar api para remover o arquivo (criar endpoint)
      console.log('Removendo arquivo')
      this.files.splice(this.files.indexOf(file), 1)
    },
    upload (file) {
      console.log('Fazendo upload ', file)
      file.done = true
      file.copying = true
      file.progress = 0
      this.$progress = setInterval(() => {
        let p = file.progress + Math.floor(Math.random() * 8 + 10)
        file.progress = p < 100 ? p : 100
        if (file.progress >= 100) {
          clearInterval(this.$progress)
        }
      }, 20)

      /*return new Promise((resolve, reject) => {
        setTimeout(() => {
          file.copying = false
          file.progress = 100
          file.success = true
          resolve('done!')
        }, 3000)
      });*/
      return new Promise((resolve, reject) => {
        fileToBase64(file.file)
            .then(_file => {
              this.uploadService(this.parseProps.pessoa.id,{
                data: _file,
                ...file
              })
                  .then(response => {
                    this.successCallback && this.successCallback(response)
                    file.id = response.data.id
                    file.copying = false
                    file.progress = 100
                    file.success = true
                    resolve(true)
                  })
                  .catch(error => {
                    this.failureCallback && this.failureCallback(error)
                    file.done = false
                    file.copying = false
                    file.progress = 0
                    file.success = false
                    file.fail = true
                    this.alertApiError(error)
                    resolve(false)
                  })
            })
      })
    },
    async iniciaEnvios () {
      console.log('Iniciando envio...')
      if (!this.files.length) {
        return
      }
      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].done) {
          await this.upload(this.files[i])
        }
      }
    },
    filesize (size) {
      return humanStorageSize(size)
    },
    successCallback (response) {
      console.log('Novo arquivo event', response.data)
      this.appContainer.$emit('novoArquivo', response.data)
    },
    reset () {
      this.files = []
      this.isUploading = false
    }
  }
}
</script>
