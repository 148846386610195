<template>
  <li class="no-icon">
    <h4><span class="title">{{h.message}}</span></h4>
    <p class="firstLetterUppercase">{{ h.date.date|formatDateDistance }} em {{ h.date.date|formatDate }}
      <span class="withSeparator">{{h.person || h.user}} <!--(Aplicativo web)--></span></p>
  </li>
</template>

<script>
import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"

export default {
  name: "FlowSimple",
  mixins: [flowMixin]
}
</script>
