<template>
  <li class="with-box file">
    <i class="box-icon material-icons">backup</i>
    <div v-for="(o, index) in list" :key="index" :class="{'box-diviser': list.length > 1}">
      <div class="with-box-content flex">
        <div class="flex flex-center m-r">
          <i class="file-icon fa" :class="'fa-' + bindIcon(o.extension)"></i>
        </div>
        <div class="file-details">
          <div :class="`m-t-xs ${isRemoved ? 'removed-file' : null}`"><a @click="download(o, true)">{{ o.originalFilename }}</a></div>
          <div class="flow-info m-t-xs">
            <p class="firstLetterUppercase"><span v-if="isRemoved">Removido </span>{{ h.date.date|formatDateDistance }} em
              {{ h.date.date|formatDate }} <span
                class="withSeparator">{{ h.person }}</span> <span class="withSeparator">{{ filesize(o.size) }}</span>
            </p>
          </div>
        </div>
      </div>
      <button class="box-menu" v-if="!isRemoved">
        <i class="fal fa-ellipsis-h"></i>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <!-- @TODO: <RAPHAEL> Aproveitar Arquivos.vue dentro de Bem, tem o método excluir pronto -->
              <menu-options-item @click.native="download(o)" label="Baixar" close/>
              <menu-options-item @click.native="excluir(o, index)" label="Excluir" close />
              <!--<menu-options-item label="Adicionar Comentário" close />-->
              <!-- @TODO: <RAPHAEL> Abrir um window <D> para fazer o comentário -->
            </ul>
          </div>
        </u-popover>
      </button>
    </div>
  </li>
</template>

<script>
import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"
import {humanStorageSize} from "uloc-vue/src/utils/format"
import fileType from "@/mixins/fileType"
import MenuOptionsItem from "@/components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import {deleteArquivo, downloadArquivoPessoa} from "@/domain/pessoa/services"

export default {
  name: "FlowFile",
  components: {MenuOptionsItem, UPopover},
  mixins: [flowMixin, fileType],
  props: {
    isRemoved: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      downloading: false
    }
  },
  computed: {
    list() {
      if (this.obj && this.obj.list) {
        return this.obj.list
      }
      return [this.obj]
    }
  },
  methods: {
    filesize(size) {
      return humanStorageSize(size)
    },
    download(arquivo, inline = false) {
      arquivo.downloading = true
      let method = (a, b) => downloadArquivoPessoa(this.appContainer.pessoa, a, b)
      if (this.appContainer.intercept) {
        method = this.appContainer.intercept.downloadArquivo
      }
      method(arquivo.id, inline)
          .then(response => {
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
    },
    excluir(h, indexRemovedItem) {
      this.$uloc.dialog({
        title: 'Remover arquivo',
        message: `Tem certeza que deseja excluir esta arquivo? O sistema irá registrar o log de exclusão.`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não'
      })
          .then(() => {
            console.log(h)
            let method = (data) => deleteArquivo(this.appContainer.pessoa, data)
            if (this.appContainer.intercept) {
              method = this.appContainer.intercept.deleteArquivo
            }
            method(h.id).then(({data}) => {
              this.$emit('remove', this.h, indexRemovedItem)
            })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    }
  }
}
</script>

<style scoped>
.removed-file {
  text-decoration: line-through;
  font-style: italic;
}
</style>
