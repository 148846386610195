<template>
  <observacoes :parse-props="parseProps" :app-methods="appMethods" :type="1" />
</template>

<script>
import Observacoes from "@/components/pessoa/components/include/pessoa/apps/Observacoes"
export default {
  name: "Contatos",
  props: ['parseProps', 'appMethods'],
  components: {Observacoes}
}
</script>
