<template>
  <div class="box-contato">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PessoaBox",
}
</script>
