<template>
<pessoa-aba>
  <div class="wrapper m-t-n m-b">
    App indisponível no momento.
  </div>
</pessoa-aba>
</template>

<script>
// @TODO: <URGENTE>
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
export default {
  name: "PessoaComunicacao",
  components: {PessoaAba},
  mixins: [AppMixin]
}
</script>
