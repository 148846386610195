<template>
  <div class="itemAba" :class="{'is-loading': loading}">
    <slot v-if="!loading" />
    <sl-loading v-else content="" loading-style="coffe__DELETED" />
  </div>
</template>

<script>
import SlLoading from "@/components/layout/components/Loading"
export default {
  name: "PessoaAba",
  components: {SlLoading},
  props: {
    loading: Boolean
  }
}
</script>
