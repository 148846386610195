export default {
    inject: ['appContainer'],
    props: ['h'],
    computed: {
        logType () {
            return this.h.action.split('.')
        },
        subject () {
            if (typeof this.logType[1] === 'undefined') {
                return 'Alteração'
            }
            return this.logType[1].charAt(0).toUpperCase() + this.logType[1].slice(1)
        },
        action () {
            let index = this.logType.length - 1
            if (typeof this.logType[index] === 'undefined') {
                return null
            }
            return this.logType[index]
        },
        obj () {
            return this.h.newObject
        }
    }
}
